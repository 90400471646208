import React, { useState, useEffect } from "react";
import Layout from "../layouts/index";
import SEO from "../components/SEO";
import LazyIframe from "../components/LazyIframe";
import Link from "gatsby-link";
import MailChimpBanner from "../components/Mailchimp-banner";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import chat from "../images/icons/chat.svg";
import bell from "../images/icons/bell.svg";
const axios = require("axios").default;

const iconStyle = {
  width: "20px",
  marginRight: "5px",
};

const mainButton = {
  padding: "12px 17px",
};

const YoutubePage = () => {
  const [videos, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      // code to run on component mount
      const channelID = "UC0fspKfWI99xkoh-RcsNFew";
      const apiKey = "AIzaSyAcpjdEgiBisY8r1xjofDjwgVl451aPNLA";

      //www.googleapis.com/youtube/v3/search?key=${apiKey}&channelId=${channelID}&part=snippet,id&order=date&maxResults=50

      const fetchData = async () => {
        setIsLoading(true);
        const result = await axios({
          url: `https://www.googleapis.com/youtube/v3/search?key=${apiKey}&channelId=${channelID}&part=snippet,id&order=date&maxResults=50`,
          method: "get",
        });
        if (result && result.data.items) {
          const videos = result.data.items.filter((item) => item.id.videoId);
          setData(videos);

          setIsLoading(false);
        }
      };
      fetchData();
    }
  }, []);

  return (
    <Layout bodyclassName="page-services">
      <SEO
        title={
          "HowToVietnamese YouTube Channel | Learn Southern Vietnamese with HowToVietnamese"
        }
      />

      <div
        className="jumbotron jumbotron-fluid margin-from-header mt-5 mt-md-7 mb-0 pt-5 pt-md-8 pb-7 pb-md-8 bg-grey"
        id="home-banner"
      >
        <div className="container d-flex justify-content-center pl-md-0 pr-md-0 ">
          <div className="col-12 col-md-11">
            <div className="row d-flex justify-content-between">
              <div className="col-12 col-md-6 pr-md-2 text-left flex-column d-flex justify-content-center">
                <h1 className="display-4">YouTube Channel</h1>
                <h4 className="text-muted pt-1">
                  Brings you interesting topics and well-produced video lessons
                  on Southern Vietnamese.
                </h4>
                <br />
                <div>
                  <button className="pl-0 align-left">
                    <Link
                      target="_blank"
                      to="/contact"
                      className="btn btn-primary"
                      style={mainButton}
                    >
                      <img
                        style={iconStyle}
                        src={chat}
                        alt="suggest episode button"
                      ></img>
                      Suggest Topic
                    </Link>
                  </button>
                  <button className="pl-0 align-left">
                    <OutboundLink
                      target="_blank"
                      href="https://bit.ly/2UigvLu"
                      className="btn btn-primary"
                      style={mainButton}
                    >
                      <img
                        style={iconStyle}
                        src={bell}
                        alt="support podcast"
                      ></img>
                      Subscribe
                    </OutboundLink>
                  </button>
                </div>
              </div>

              <div className="col-md-6 hide-on-mobile">
                <LazyIframe
                  key="https://www.youtube.com/embed/C-GOTd18kOw"
                  src="https://www.youtube.com/embed/C-GOTd18kOw"
                  height="280"
                  width="100%"
                  frameBorder="0"
                ></LazyIframe>
                {/* <img src={HomepageImage} className="homeBanner"></img> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container pl-2 pr-2 pb-4">
        <div className="row mt-6 mt-md-8 mb-2 mb-md-4 align-center">
          <h1>Recent Videos</h1>
        </div>

        {isLoading ? (
          <div className="row">Loading ...</div>
        ) : (
          <div className="row">
            {videos.map((video) => {
              return (
                <div
                  key={video.id.videoId}
                  className="col-12 col-sm-4 pl-0 pr-0 pl-md-1 pr-md-1 pb-1"
                >
                  <LazyIframe
                    key={video.id.videoId}
                    src={`https://www.youtube.com/embed/${video.id.videoId}`}
                    height="200"
                    width="100%"
                    frameBorder="0"
                  ></LazyIframe>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <div className="col-12 no-bg">
        <MailChimpBanner />
      </div>
    </Layout>
  );
};

export default YoutubePage;
